import { Fragment, ReactNode, useState } from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'
import { media } from 'bl-common/src/utils/media'
import { isEdge } from 'bl-utils/src/isEdge'

import BrowserOnly from './BrowserOnly'

type VideoHeroProps = {
  video?: any
  videoHq?: any
  videoMobile?: any
  fallback?: any
  poster?: any
  posterMobile?: any
  children?: ReactNode
}

type VideoContainerProps = {
  fallbackImage?: string
}

const VideoContainer = styled.div<VideoContainerProps>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 10px;

  ${media.md(css`
    margin-bottom: 18px;
    &::before,
    &::after {
      width: ${({ theme }) => theme.spacing[1]};
    }
  `)};

  ${props =>
    props.fallbackImage &&
    css`
      background-image: url(${props.fallbackImage});
      background-size: cover;
    `};
`

const Video = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: opacity ${durations.long * 2}ms;
`

const Content = styled.div`
  height: 100%;
  position: relative;
  z-index: ${zIndex.above};
`

export const VideoHero = ({
  video,
  videoHq,
  videoMobile,
  fallback,
  poster,
  posterMobile,
  children,
}: VideoHeroProps) => {
  const [shouldPlay, setShouldPlay] = useState(false)
  // TODO: Switch to useBreakpoints
  const width = useObserver(observe(viewportWidth(), vw => vw))

  const onPlay = () => {
    window.requestAnimationFrame(() => setShouldPlay(true))
  }

  return (
    <VideoContainer
      fallbackImage={
        (width as number) <= 415 && posterMobile
          ? getImageUrl(posterMobile, { width: 415, ssr: true })
          : getImageUrl(poster, { width: 1280, ssr: true })
      }
      data-video-hero
    >
      {children && <Content>{children}</Content>}
      <BrowserOnly>
        {!isEdge && (
          <Video
            autoPlay
            muted
            loop
            playsInline
            onPlay={onPlay}
            style={{ opacity: shouldPlay ? 1 : 0 }}
          >
            <Fragment>
              {(width as number) >= breakpoints.lg ? (
                <source
                  src={get(videoHq, 'fields.file.url')}
                  type={get(videoHq, 'fields.file.contentType')}
                />
              ) : (width as number) > 415 ? (
                <source
                  src={get(video, 'fields.file.url')}
                  type={get(video, 'fields.file.contentType')}
                />
              ) : (
                <source
                  src={get(videoMobile, 'fields.file.url')}
                  type={get(videoMobile, 'fields.file.contentType')}
                />
              )}
              <source
                src={get(fallback, 'fields.file.url')}
                type={get(fallback, 'fields.file.contentType')}
              />
            </Fragment>
          </Video>
        )}
      </BrowserOnly>
    </VideoContainer>
  )
}
