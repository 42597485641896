interface BlobProps {
  colorOne: string
  colorTwo: string
  opacity?: number
  blur?: number
}

export const BlobFrown = ({ colorOne, colorTwo, opacity, blur }: BlobProps) => {
  return (
    <svg
      style={{ filter: `blur(${blur ?? 0}px)` }}
      viewBox="0 0 1275 776"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity ?? 1}
        d="M1121.09 16.997C739.571 -80.3463 215.785 272.612 1.58236 461.26C-25.3767 545.56 739.059 607.377 771.255 749.436C811.499 927.011 1597.99 138.676 1121.09 16.997Z"
        fill="url(#paint0_linear_406_456)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_406_456"
          x1="-382.611"
          y1="619.133"
          x2="289.855"
          y2="1422.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorOne} />
          <stop offset="1" stopColor={colorTwo} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const BlobSmile = ({ colorOne, colorTwo, opacity, blur }: BlobProps) => {
  return (
    <svg
      style={{ filter: `blur(${blur ?? 0}px)` }}
      viewBox="0 0 1209 805"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity ?? 1}
        d="M185.346 803.617C578.09 831.613 1030.82 391.192 1208.09 167.482C1219.64 79.7328 456.382 154.745 399.454 20.6679C328.294 -146.929 -305.584 768.622 185.346 803.617Z"
        fill="url(#paint0_linear_406_457)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_406_457"
          x1="1558.11"
          y1="-56.1521"
          x2="753.503"
          y2="-727.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorOne} />
          <stop offset="1" stopColor={colorTwo} />
        </linearGradient>
      </defs>
    </svg>
  )
}
