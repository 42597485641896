import 'pure-react-carousel/dist/react-carousel.es.css'

import { useEffect, useState } from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import { BLOCKS } from '@contentful/rich-text-types'
import { useWindowSize } from '@react-hookz/web'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import styled, { createGlobalStyle, css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { gridOffsetFrom, gridOffsetTo } from 'bl-common/src/constants/sizes'
import { Amount } from 'bl-common/src/elements/Amount'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IGradient } from 'bl-common/src/generated/contentful'
import { ButtonLink } from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Section } from 'bl-common/src/units/Section/Section'
import { VisuallyHidden } from 'bl-common/src/units/VisuallyHidden'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

import {
  HighlightsPlayer,
  HighlightsThumbnail,
  LeftChevron,
  RightChevron,
} from '../../../Highlights'

const CarouselStyles = createGlobalStyle`
  ${media.md(css`
    .slide:first-child {
      margin-left: 0 !important;
    }
  `)}
  .slideInner div { outline-width: 0px; }

  .HighlightsCarousel {
    margin-top: ${({ theme }) => theme.spacing[2]};

    ${media.md(css`
      margin-top: ${({ theme }) => theme.spacing[4]};
    `)}
  }

  .HighlightsCarousel-slider {
    overflow: visible;
    touch-action: pan-y;
  }

  .HighlightsCarousel-tray {
    transition: transform ${durations.short}ms;
  }

  .HighlightsCarousel-button {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 1;

    &[disabled] {
      opacity: 0;
    }

    &--next {
      right: ${between(-gridOffsetFrom * 0.5, -gridOffsetTo * 0.5)};
    }

    &--back {
      left: ${between(-gridOffsetFrom * 0.5, -gridOffsetTo * 0.5)};
    }

    > svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    ${media.md(css`
      display: block;
      top: 84px;
    `)}
  }
`

const CarouselContainer = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.spacing[3.5]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  ${mixins.siteGutter()}

  ${media.md(css`
    padding-top: ${({ theme }) => theme.spacing[7.5]};
    padding-bottom: ${({ theme }) => theme.spacing[7.5]};
  `)}
`

const CarouselContainerInner = styled.div`
  position: relative;
  z-index: 1;
`

const CarouselContent = styled.div`
  max-width: 75%;

  ${media.md(css`
    max-width: ${mixins.span({ columns: 7, gutters: 6 })};
  `)}
`

const SectionDecorator = styled.div<{ gradient?: IGradient }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 64px;

  .t-dark & {
    opacity: 0.1;
  }

  ${({
    gradient = {
      fields: {},
    },
  }) => {
    const { color1, color2 } = gradient.fields
    return (
      color1 &&
      color2 &&
      css`
        background: linear-gradient(${color1}, ${color2});
      `
    )
  }};

  ${media.md(css`
    right: auto;
    width: 67.5%;
    max-width: 1500px;
  `)}
`

const NATURAL_SLIDE_WIDTH_MOBILE = 200
const NATURAL_SLIDE_WIDTH_DESKTOP = 240
const NATURAL_SLIDE_HEIGHT_MOBILE = 112.5
const NATURAL_SLIDE_HEIGHT_DESKTOP = 135

const documentOptiuons = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2 }}
        top={{ xs: 1, md: 1 }}
        maxWidth={400}
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type weight="normal" size={{ xs: 14, md: 24 }}>
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <Button
              paddingSize={fields.paddingSize}
              preset={fields.preset}
              to={fields.link}
              maxWidth={373}
              top={{ xs: 1.5, md: 2.5 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
        case 'prices':
          return (
            <Type
              preset="text"
              as="p"
              lineHeight={2}
              weight="bold"
              color={colors.deepBlue}
              top={{ xs: 1 }}
            >
              <Amount
                value={fields.isk}
                format={fields.priceFormat}
                useSymbol
              />
            </Type>
          )
        default:
          return null
      }
    },
  },
}

export const HighlightsCarousel = ({
  section: {
    fields: { highlights, title, description, gradient, config },
  },
}) => {
  const { width } = useWindowSize()

  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  const NATURAL_SLIDE_WIDTH = 200
  const desktopSlidesThatFit =
    Math.floor(width / NATURAL_SLIDE_WIDTH_DESKTOP) - 2
  const [selectedCollection, setSelectedCollection] = useState(null)

  if (!isReady) {
    return null
  }

  return (
    <>
      <CarouselStyles />

      <Section
        config={config}
        noHorizontalPadding
        top={{ xs: 0, md: 2 }}
        hideOverflowX
      >
        <CarouselContainer>
          <CarouselContainerInner>
            {title ? (
              <Type
                as="h2"
                size={{ xs: 24, md: 48 }}
                weight="bold"
                bottom={{ xs: 1 }}
              >
                {title}
              </Type>
            ) : null}

            {!!description && (
              <CarouselContent>
                <RichTextRenderer
                  document={description}
                  customOptions={documentOptiuons}
                />
              </CarouselContent>
            )}

            <CarouselProvider
              naturalSlideHeight={
                isMobile
                  ? NATURAL_SLIDE_HEIGHT_MOBILE
                  : NATURAL_SLIDE_HEIGHT_DESKTOP
              }
              naturalSlideWidth={
                isMobile
                  ? NATURAL_SLIDE_WIDTH_MOBILE
                  : NATURAL_SLIDE_WIDTH_DESKTOP
              }
              totalSlides={highlights.length}
              visibleSlides={isMobile ? 2.1 : desktopSlidesThatFit}
              isIntrinsicHeight={true}
              dragEnabled={false}
              className="HighlightsCarousel"
            >
              <Slider
                style={{
                  margin: 0,
                  outline: 'none',
                }}
                classNameAnimation="HighlightsCarousel-tray"
                className="HighlightsCarousel-slider"
              >
                {highlights.map((collection, index) => {
                  const isFirst = index === 0
                  const { collectionName, thumbnail, highlights, link } =
                    collection.fields
                  return (
                    <Slide
                      key={collectionName}
                      index={collectionName}
                      style={{
                        width: isMobile ? `${NATURAL_SLIDE_WIDTH}px` : 'auto',
                        margin:
                          isFirst && isMobile
                            ? '0 12px 0 0'
                            : isMobile
                              ? '0 12px'
                              : `0 ${between(12, 20)}`,
                      }}
                      className="slide"
                      innerClassName="slideInner"
                    >
                      <HighlightsThumbnail
                        name={collectionName}
                        image={thumbnail}
                        link={link}
                        onClick={
                          highlights
                            ? () => {
                                setSelectedCollection(collection)
                              }
                            : null
                        }
                      />
                    </Slide>
                  )
                })}
              </Slider>
              {highlights.length > (isMobile ? 2.1 : desktopSlidesThatFit) ? (
                <>
                  <ButtonNext
                    className="HighlightsCarousel-button HighlightsCarousel-button--next"
                    aria-labelledby="highlights-carousel-next"
                  >
                    <>
                      <VisuallyHidden id="highlights-carousel-next">
                        Next
                      </VisuallyHidden>
                      <RightChevron aria-hidden />
                    </>
                  </ButtonNext>
                  <ButtonBack
                    className="HighlightsCarousel-button HighlightsCarousel-button--back"
                    aria-labelledby="highlights-carousel-previous"
                  >
                    <>
                      <VisuallyHidden id="highlights-carousel-previous">
                        Previous
                      </VisuallyHidden>
                      <LeftChevron aria-hidden />
                    </>
                  </ButtonBack>
                </>
              ) : null}
            </CarouselProvider>
          </CarouselContainerInner>
          {gradient ? <SectionDecorator gradient={gradient} /> : null}
        </CarouselContainer>
      </Section>
      {!!selectedCollection && (
        <HighlightsPlayer
          show={!!selectedCollection}
          onHide={() => setSelectedCollection(null)}
          highlights={selectedCollection?.fields.highlights}
          name={selectedCollection?.fields.collectionName}
        />
      )}
    </>
  )
}
