import get from 'lodash/get'

import { SmartImage } from '../units/SmartImage/SmartImage'

const getImageMeta = image => {
  const { width, height } = get(image, 'fields.file.details.image', {
    width: 0,
    height: 0,
  })
  const description = get(image, 'fields.description', '')
  const aspectRatio = width && (height / width) * 100

  return { aspectRatio, description }
}

export const BackgroundImage = ({
  image,
  useAspectRatio = false,
  className = '',
}) => {
  let imageInfo = { aspectRatio: 0, description: '' }
  let paddingBottom = {}

  if (image.sys) {
    imageInfo = getImageMeta(image)
    paddingBottom = useAspectRatio
      ? { paddingBottom: `${imageInfo.aspectRatio}%` }
      : {}
  }
  return (
    <SmartImage image={image} styles>
      {({ style }) => (
        <div
          style={{ ...style, ...paddingBottom }}
          role="img"
          aria-label={imageInfo.description}
          className={className}
        />
      )}
    </SmartImage>
  )
}
