import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import { Type } from '../../elements/Typography/Typography'
import { ISectionRichTextFields } from '../../generated/contentful'
import { RichTextRenderer } from '../../richText/RichTextRenderer'
import { Appear } from '../Appear'
import { Column } from '../Column'
import { Row } from '../Row'

type RichTextSectionProps = Pick<ISectionRichTextFields, 'content'>

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type as="p" preset="textLarge" mTop={{ xs: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        as="h2"
        preset="headlineLarge"
        mTop={{ xs: 2.5, md: 4 }}
        mBottom={{ xs: 1, md: 2 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Type
        as="h3"
        preset="headlineMedium"
        mTop={{ xs: 2.5, md: 4 }}
        mBottom={{ xs: 1, md: 1.5 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Type
        as="h4"
        preset="headlineSmall"
        mTop={{ xs: 2.5, md: 4 }}
        mBottom={{ xs: 1 }}
      >
        {children}
      </Type>
    ),
  },
}

export const RichTextSection = ({ content }: RichTextSectionProps) => {
  return (
    <Appear observer>
      <Appear>
        <Row>
          <Column size={{ xs: 1 / 1, md: 8 / 12 }} offsetLeft={{ md: 2 / 12 }}>
            <RichTextRenderer document={content} customOptions={options} />
          </Column>
        </Row>
      </Appear>
    </Appear>
  )
}
