import { RichTextSection as _RichTextSection } from 'bl-common/src/units/RichTextSection/RichTextSection'
import { Section } from 'bl-common/src/units/Section/Section'

import { getSectionProps } from '../../../utils/getSectionProps'

const RichTextSection = props => {
  const { config, content } = getSectionProps(props)

  return (
    <Section
      bottom={{ xs: 4.5, md: 5 }}
      top={{ xs: 4.5, md: 5 }}
      config={config}
    >
      <_RichTextSection content={content} />
    </Section>
  )
}

export default RichTextSection
