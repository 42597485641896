import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { LinkArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'

type ModalCardProps = {
  imageUrl: string
  title: string
  description: string
  rounded?: boolean
  small?: boolean
  ctaLabel: string
  ctaDisplayAsButton?: boolean
  ctaLinkTo?: string
  ctaOnClick?: () => void
}

type CardInnerProps = {
  rounded?: ModalCardProps['rounded']
  small?: ModalCardProps['small']
}

type CardHeadProps = {
  imageUrl: ModalCardProps['imageUrl']
  small?: ModalCardProps['small']
}

type CardBodyProps = {
  small?: ModalCardProps['small']
}

type CardContentProps = {
  small?: ModalCardProps['small']
}

type CardWrapperProps = {
  to?: string
  onClick?: () => void
}
const CardWrapper = styled((props: CardWrapperProps) =>
  props.to ? <Link {...props} /> : <div {...props} />
)(() => ({ to, onClick }) => ({
  width: '100%',
  display: 'block',
  cursor: onClick || to ? 'pointer' : 'default',

  ':hover, :focus': {
    [`${CardInner}`]: {
      transform: 'translateY(-16px)',
    },

    [`${CardHead}::before`]: {
      transform: 'scale(1.1)',
    },

    [`${AnchorIcon}`]: {
      transform: 'translateX(8px)',
    },
  },
}))

const CardInner = styled.div<CardInnerProps>(({ theme, rounded, small }) => ({
  position: 'relative',
  borderRadius: rounded ? theme.spacing[small ? 1.5 : 2] : 0,
  overflow: 'hidden',
  boxShadow: '0 12px 20px rgba(0, 0, 0, 0.08)',
  transition: `transform ${durations.long}ms ease`,
}))

const CardHead = styled.div<CardHeadProps>(({ imageUrl, small }) => ({
  position: 'relative',
  overflow: 'hidden',

  '&::after': {
    content: '""',
    display: 'block',
    top: 0,
    left: 0,
    paddingBottom: small ? '63%' : '65%',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: `transform ${durations.long}ms ease`,
  },
}))

const CardBody = styled.div<CardBodyProps>(({ theme, small }) => ({
  paddingTop: theme.spacing[small ? 3 : 2.5],
  paddingBottom: theme.spacing[small ? 3 : 2.5],
  paddingLeft: theme.spacing[small ? 2 : 2.5],
  paddingRight: theme.spacing[small ? 2 : 2.5],
  backgroundColor: colors.white,
  transition: `background-color ${durations.medium}ms ease`,

  '.t-dark &, .gradient-dark &': {
    backgroundColor: colors.darkmodeOffset,
  },
}))

const CardContent = styled.div<CardContentProps>(({ theme, small }) => ({
  minHeight: theme.spacing[small ? 12 : 16],
}))

const CtaWrapper = styled.div(({ theme }) => ({
  marginBottom: theme.spacing[1],
}))

export const AnchorWrapper = styled.div({
  color: colors.deepBlue,
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  marginTop: 'auto',
  width: '100%',

  '.t-dark &, .gradient-dark &': {
    color: colors.blueOnDark,
  },
})

export const AnchorIcon = styled.div({
  minWidth: 12,
  transition: `transform ${durations.long}ms ease`,
})

export const ModalCard = ({
  imageUrl,
  title,
  description,
  rounded,
  small,
  ctaLabel,
  ctaDisplayAsButton,
  ctaLinkTo,
  ctaOnClick,
}: ModalCardProps) => {
  const wrapperProps = ctaLinkTo
    ? {
        to: ctaLinkTo,
      }
    : {
        onClick: ctaOnClick,
      }

  return (
    <CardWrapper {...wrapperProps}>
      <CardInner rounded={rounded} small={small}>
        <CardHead imageUrl={imageUrl} small={small} />

        <CardBody small={small}>
          <CardContent small={small}>
            <Type
              as="h3"
              size={small ? { xs: 24, md: 32 } : { xs: 32, md: 42, lg: 48 }}
              weight="bold"
              bottom={{ xs: 1, md: 1.2 }}
            >
              {title}
            </Type>
            <Type as="p" preset="textLarge" bottom={{ xs: 1, md: 2 }}>
              {description}
            </Type>
          </CardContent>
          {(ctaLinkTo || ctaOnClick) && (
            <CtaWrapper>
              {ctaDisplayAsButton && ctaOnClick ? (
                <Button onClick={ctaOnClick}>{ctaLabel}</Button>
              ) : ctaLinkTo ? (
                <AnchorWrapper>
                  <Type weight="bold" right={{ xs: 0.5 }} preset="text">
                    {ctaLabel}
                  </Type>
                  <AnchorIcon>
                    <LinkArrow thick />
                  </AnchorIcon>
                </AnchorWrapper>
              ) : null}
            </CtaWrapper>
          )}
        </CardBody>
      </CardInner>
    </CardWrapper>
  )
}
