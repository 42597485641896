import { useState } from 'react'
import dynamic from 'next/dynamic'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { HeroOfferCard } from 'bl-common/src/cards/HeroOfferCard/HeroOfferCard'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Hero } from 'bl-common/src/units/Hero'
import { LightBoxCollection } from 'bl-common/src/units/LightBox/LightBoxCollection'
import { Section } from 'bl-common/src/units/Section/Section'
import { SectionContent as Content } from 'bl-common/src/units/Section/SectionContent'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'

import { formatHeroOfferCard } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'
import { RenderComponent } from '../../RenderComponent'

const Cinema = dynamic(() => import('bl-common/src/units/Cinema/Cinema'), {
  ssr: false,
})

const SectionContent = styled(Content)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonPositioner = styled.div`
  display: flex;
  ${mediaMax.md(css`
    margin-top: auto;
  `)};
`

const LightboxButton = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing[7]};
  z-index: ${zIndex.above};
`

const CinemaButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const PlayIcon = () => (
  <svg
    width={between(70, 114)}
    height={between(70, 114)}
    viewBox="0 0 114 114"
    fill="none"
  >
    <circle opacity="0.8" cx="57" cy="57" r="57" fill="black" />
    <path
      opacity="0.8"
      d="M44.375 79.4934C43.9428 79.4934 43.5105 79.3512 43.1258 79.065C42.4212 78.5438 42 77.6397 42 76.6483V36.8439C42 35.8526 42.4212 34.9485 43.1258 34.4272C43.8319 33.906 44.6964 33.8586 45.4358 34.3021L78.6858 54.2043C79.4965 54.6877 80 55.6619 80 56.7461C80 57.8303 79.4965 58.8046 78.6874 59.2898L45.4374 79.192C45.1002 79.3929 44.736 79.4934 44.375 79.4934ZM44.3766 35.8943C44.1818 35.8943 44.0314 35.9853 43.957 36.0384C43.8462 36.1218 43.5833 36.3682 43.5833 36.8439V76.6483C43.5833 77.1241 43.8462 77.3705 43.9586 77.4539C44.0726 77.5354 44.3734 77.7117 44.7297 77.4956L77.9797 57.5934C78.252 57.4304 78.4167 57.112 78.4167 56.7461C78.4167 56.3803 78.252 56.0619 77.9797 55.8989L44.7297 35.9967C44.603 35.9208 44.4843 35.8943 44.3766 35.8943Z"
      fill="white"
    />
  </svg>
)

const Icon = styled.img<{ offsetDirection?: 'right' | 'left' }>`
  position: absolute;
  height: ${({ theme }) => theme.spacing[6]};
  width: ${({ theme }) => theme.spacing[6]};
  top: ${({ theme }) => theme.spacing[2]};
  right: ${({ theme }) => theme.spacing[5]};

  ${media.md(css`
    right: ${({ theme }) => theme.spacing[7]};
    height: ${({ theme }) => theme.spacing[10]};
    width: ${({ theme }) => theme.spacing[10]};
  `)}

  ${({ offsetDirection }) =>
    offsetDirection === 'right' &&
    css`
      right: ${({ theme }) => theme.spacing[2]};

      ${media.md(css`
        right: ${({ theme }) => theme.spacing[2]};
      `)}
    `}
`

// TODO: Go over this component, why are there two named the same, is this one not used?

export const HeroWithContentSection = props => {
  const {
    heading,
    multilineSubheading,
    textAlignment,
    image,
    video,
    videoId,
    ctaButton,
    offerCard,
    offsetDirection,
    backgroundContrast,
    frameGradient,
    lightbox,
    icon,
    config,
  } = getSectionProps(props)
  const [showCinema, setShowCinema] = useState(false)

  const contentAlignStyle = {
    marginLeft:
      textAlignment === 'right' || textAlignment === 'center'
        ? 'auto'
        : undefined,
    marginRight: textAlignment === 'center' ? 'auto' : undefined,
  }

  const heroOfferCard = offerCard && formatHeroOfferCard(offerCard)
  return (
    <>
      <Section
        noHorizontalPadding
        top={{ xs: 3, md: 6 }}
        bottom={{ xs: 3, md: 6 }}
        config={config}
        hideOverflowX
      >
        <Appear>
          <Hero
            size="content"
            offsetDirection={offsetDirection}
            image={image}
            video={video}
            backgroundContrast={backgroundContrast}
            style={{ display: 'flex' }}
            frameGradient={frameGradient}
          >
            <SectionContent
              textColor="light"
              top={{ xs: 4.5, md: 12 }}
              bottom={{ xs: 4, md: 12 }}
            >
              {heading && (
                <Type
                  as="h3"
                  preset="headlineLarge"
                  weight="medium"
                  bottom={{ xs: 0.5, md: 1 }}
                  style={{
                    maxWidth: '80%',
                    textAlign: textAlignment,
                    ...contentAlignStyle,
                  }}
                >
                  {heading}
                </Type>
              )}
              {multilineSubheading && (
                <Type
                  preset="subtitle"
                  bottom={{ xs: 2, md: 6 }}
                  style={{ textAlign: textAlignment }}
                  multiline
                >
                  {multilineSubheading}
                </Type>
              )}

              <RenderComponent
                componentData={ctaButton}
                contentType="ctaButton"
              >
                {component => (
                  <ButtonPositioner key={get(component, 'sys.id')}>
                    <Button
                      paddingSize={get(component, 'fields.paddingSize')}
                      preset={get(component, 'fields.color')}
                      to={get(component, 'fields.link')}
                      minWidth={256}
                      emailTrigger={get(component, 'fields.emailTrigger')}
                      style={{
                        ...contentAlignStyle,
                      }}
                    >
                      {get(component, 'fields.text')}
                    </Button>
                  </ButtonPositioner>
                )}
              </RenderComponent>
              {lightbox && (
                <LightboxButton>
                  <LightBoxCollection images={get(lightbox, 'fields.images')}>
                    {({ open }) => (
                      <Button minWidth={256} onClick={open} preset="grey">
                        {get(lightbox, 'fields.label')}
                      </Button>
                    )}
                  </LightBoxCollection>
                </LightboxButton>
              )}
            </SectionContent>
            {videoId && (
              <CinemaButton>
                <Button onClick={() => setShowCinema(true)} noHover transparent>
                  <PlayIcon />
                </Button>
              </CinemaButton>
            )}
            {heroOfferCard && (
              <HeroOfferCard
                key={heroOfferCard.id + 'desktop'}
                title={heroOfferCard.title}
                text={heroOfferCard.text}
                button={heroOfferCard.button}
              />
            )}
            {icon && (
              <SmartImage image={icon}>
                {imageProps => (
                  <Icon src={imageProps} offsetDirection={offsetDirection} />
                )}
              </SmartImage>
            )}
          </Hero>
          {heroOfferCard && (
            <HeroOfferCard
              key={heroOfferCard.id + 'mobile'}
              title={heroOfferCard.title}
              text={heroOfferCard.text}
              button={heroOfferCard.button}
              isMobile
            />
          )}
        </Appear>
      </Section>
      <Cinema
        show={showCinema}
        onHide={() => setShowCinema(false)}
        videoId={videoId}
      />
    </>
  )
}

export default HeroWithContentSection
