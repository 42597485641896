import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'

const ProgressWrap = styled.div`
  display: flex;
  position: relative;

  > * + * {
    margin-left: 12px;
  }
`

const ProgressItem = styled.div<{ isDone?: boolean }>`
  flex: 1;
  height: 3px;
  overflow: hidden;
  position: relative;
  background: ${({ isDone }) => (isDone ? colors.deepBlue : 'white')};
`

const ProgressInner = styled.div`
  height: 3px;
  background: ${colors.deepBlue};
  position: absolute;
  left: 0;
  width: 100%;
  transition: transform 200ms ease-in;

  transform-origin: 0%;
`

export const MediaProgress = ({
  total,
  current,
  mediaDuration = 0,
  isPlaying,
}) => {
  return (
    <ProgressWrap>
      {Array.from({ length: total }, (_, index) => (
        <ProgressItem key={index} isDone={current > index}>
          {current === index && isPlaying && (
            <ProgressInner style={{ transform: `scaleX(${mediaDuration})` }} />
          )}
        </ProgressItem>
      ))}
    </ProgressWrap>
  )
}
