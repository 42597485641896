import { ReactNode } from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { breakpoints } from '../constants/breakpoints'
import { colors } from '../constants/colors'
import { gridOffsetFrom } from '../constants/sizes'
import { ContentfulImage } from '../elements/ContentfulImage'
import { ISectionDiscoverPanelFields } from '../generated/contentful'
import { Appear } from '../units/Appear'
import { ResponsiveParallax } from '../units/ResponsiveParallax'
import { between } from '../utils/between'
import { media } from '../utils/media'

type ImagesContainerProps = {
  offsetDirection: string
  textContentSide: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md(css`
    flex-direction: row;
  `)}
`

const Content = styled.div`
  flex: 0 0 40%;
  padding: ${({ theme }) => `${theme.spacing[4.5]} ${theme.spacing[2.5]}`};
  max-width: 800px;
  overflow: hidden;

  ${media.lg(css`
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[5.5]}`};
  `)}

  ${media.xl(css`
    max-width: 1200px;
  `)}
`

const ImagesContainer = styled.div<ImagesContainerProps>`
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  ${({ offsetDirection }) => {
    if (offsetDirection === 'left') {
      return css`
        padding-right: ${({ theme }) => theme.spacing[2.5]};
        margin-left: ${-gridOffsetFrom}px;
      `
    } else if (offsetDirection === 'right') {
      return css`
        padding-left: ${({ theme }) => theme.spacing[2.5]};
        margin-right: ${-gridOffsetFrom}px;
      `
    }
  }}

  ${media.md(css<{ textContentSide: string }>`
    flex: 1 0 60%;
    padding-left: ${({ textContentSide, theme }) =>
      textContentSide === 'right' ? theme.spacing[5] : theme.spacing[2.5]};
    padding-right: ${({ textContentSide, theme }) =>
      textContentSide === 'right' ? theme.spacing[2.5] : theme.spacing[5]};
  `)}
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 325px;
`

const ImagePairItemWrapper = styled(ResponsiveParallax)`
  flex: 1 0 100%;
  :last-child {
    display: none;
  }

  ${media.md(css`
    flex: 0 0 48%;
    :last-child {
      display: block;}
      }
    }
  `)}
`

const ImageContainer = styled.div<{ offsetDirection: string }>`
  flex: 1 0 60%;
  max-width: 1200px;

  ${({ offsetDirection }) => {
    if (offsetDirection === 'left') {
      return css`
        padding-right: ${({ theme }) => theme.spacing[2.5]};
        transform: translate(
          ${({ theme }) => `-${gridOffsetFrom}px, ${theme.spacing[-2.5]}`}
        );
      `
    } else if (offsetDirection === 'right') {
      return css`
        padding-left: ${({ theme }) => theme.spacing[2.5]};
        transform: translate(
          ${({ theme }) => `${gridOffsetFrom}px, ${theme.spacing[2.5]}`}
        );
      `
    }
  }}

  ${media.md(css<{ offsetDirection: string }>`
    padding: ${({ theme }) => `0px ${theme.spacing[5]}`};
    margin-top: ${({ theme }) => theme.spacing[-5]};
    margin-bottom: ${({ theme }) => theme.spacing[-5]};
    transform: none;

    ${({ offsetDirection }) => {
      if (offsetDirection === 'left') {
        return css`
          margin-left: auto;
        `
      } else if (offsetDirection === 'right') {
        return css`
          margin-right: auto;
        `
      }
    }}
  `)}

  ${media.lg(css`
    max-width: ${between(800, 1100, {
      fromWidth: breakpoints.lg,
      toWidth: breakpoints.xl,
    })};
  `)}
`

type DiscoverPanelProps = Pick<
  ISectionDiscoverPanelFields,
  'images' | 'offsetDirection' | 'textColor' | 'textContentSide'
>

export const DiscoverPanel: React.FC<
  DiscoverPanelProps & { children: ReactNode }
> = ({
  images = [],
  offsetDirection,
  textColor,
  textContentSide = 'left',
  children,
}) => {
  return (
    <Container
      style={{ color: textColor === 'light' ? colors.light : colors.dark }}
    >
      <Content style={{ order: textContentSide === 'right' ? 1 : 0 }}>
        <Appear>{children}</Appear>
      </Content>
      {images.length > 1 ? (
        <ImagesContainer
          offsetDirection={offsetDirection}
          textContentSide={textContentSide}
        >
          {images.map((image, index) => {
            return (
              <ImagePairItemWrapper
                key={get(image, 'sys.id', image)}
                from={1}
                to={index % 2 === 0 ? -5 : 5}
                disabledMobile
              >
                <ImageWrapper>
                  <ContentfulImage image={image} />
                </ImageWrapper>
              </ImagePairItemWrapper>
            )
          })}
        </ImagesContainer>
      ) : (
        <ImageContainer offsetDirection={offsetDirection}>
          <ImageWrapper>
            <ContentfulImage image={images[0]} />
          </ImageWrapper>
        </ImageContainer>
      )}
    </Container>
  )
}
