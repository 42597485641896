import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { breakpoints } from '../constants/breakpoints'
import { zIndex } from '../constants/zIndex'
import HeroImage from '../elements/HeroImage'
import { media } from '../utils/media'

type FrameProps = {
  frameGradientColor1?: any
  frameGradientColor2?: any
  frameGradientDegrees?: any
  frameGradientOpacity?: any
  offsetDirection?: string
  expandFrame?: any
}

const Frame = styled.div<FrameProps>`
  position: relative;
  z-index: ${zIndex.above};

  ${({ frameGradientColor1, expandFrame }) =>
    frameGradientColor1 &&
    media.md(css`
      &::after {
        content: '';
        ${({
          frameGradientColor1,
          frameGradientColor2,
          frameGradientDegrees,
          frameGradientOpacity,
        }: FrameProps) => css`
          background: linear-gradient(
            ${frameGradientDegrees},
            ${frameGradientColor1},
            ${frameGradientColor2}
          );
          opacity: ${frameGradientOpacity};
        `};
        position: absolute;
        top: ${({ theme }) => theme.spacing[5]};
        right: ${({ offsetDirection, theme }) =>
          offsetDirection === 'right' ? 0 : theme.spacing[5]};
        left: ${({ offsetDirection, theme }) =>
          expandFrame ? 0 : offsetDirection === 'right' ? theme.spacing[5] : 0};
        bottom: ${({ theme }) => theme.spacing[expandFrame ? -25 : -5]};
        z-index: ${zIndex.behind};
      }
    `)};
`

/**
 * Hero types:
 * aspect: <img /> with a srcset
 * cover: <div /> with a background image and synthetic srcset.
 */

export const Hero = ({
  mobileCroppedImage = undefined,
  fullSizeImage = undefined,
  offsetDirection = undefined,
  frameGradient = undefined,
  expandFrame = undefined,
  video = undefined,
  ...rest
}) => {
  if (mobileCroppedImage) {
    // TODO: Switch to useBreakpoints
    const isMobile = useObserver(
      observe(viewportWidth(), (width: number) => width < breakpoints.md)
    )

    return (
      <HeroImage
        image={isMobile ? mobileCroppedImage : fullSizeImage}
        {...rest}
      />
    )
  }
  return (
    <Frame
      offsetDirection={offsetDirection}
      frameGradientColor1={get(frameGradient, 'color1', 'transparent')}
      frameGradientColor2={get(frameGradient, 'color2', 'transparent')}
      frameGradientDegrees={get(frameGradient, 'fields.degrees', '90deg')}
      frameGradientOpacity={get(frameGradient, 'fields.opacity', '0.8')}
      expandFrame={expandFrame}
    >
      <HeroImage
        image={fullSizeImage}
        offsetDirection={offsetDirection}
        video={video}
        {...rest}
      />
    </Frame>
  )
}

Hero.propTypes = {
  backgroundContrast: PropTypes.number,
}

Hero.defaultProps = {
  backgroundContrast: 0,
}
