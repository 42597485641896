import { BLOCKS } from '@contentful/rich-text-types'
import { between } from 'polished'
import styled, { css } from 'styled-components'

import { gradients } from '../constants/gradients'
import { gridOffsetFrom, modularScale } from '../constants/sizes'
import { zIndex } from '../constants/zIndex'
import { Button } from '../elements/Button/Button'
import { ContentfulImage } from '../elements/ContentfulImage'
import { Type } from '../elements/Typography/Typography'
import { ISectionDuoImagePanelFields } from '../generated/contentful'
import { ButtonLink } from '../richText/RichTextBlocks'
import { RichTextRenderer } from '../richText/RichTextRenderer'
import { Gradient } from '../types/custom'
import { media } from '../utils/media'
import { ResponsiveParallax } from './ResponsiveParallax'

type DuoImagePanelSectionProps = Pick<
  ISectionDuoImagePanelFields,
  | 'landscapeImage'
  | 'portraitImage'
  | 'textColor'
  | 'textContent'
  | 'textContentSide'
> & { gradient: Gradient }

type Side = 'left' | 'right'

interface ContainerProps {
  side: Side
  gradient: Gradient
}

interface ImageProps {
  side: Side
}

const Container = styled.div<ContainerProps>`
  position: relative;

  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  column-gap: 20px;
  grid-template-rows: auto 1fr;

  ${media.md(css`
    column-gap: 40px;
  `)}

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    opacity: 1;
    top: ${between(`${40 / modularScale}px`, '40px')};
    bottom: ${between(`${-40 / modularScale}px`, '-40px')};
    z-index: ${zIndex.behind};

    ${media.md(css`
      top: ${between(`${127 / modularScale}px`, '127px')};
    `)}
    ${props => css`
      background: linear-gradient(
        ${props.gradient?.color1 || gradients.blueLagoon[0]},
        ${props.gradient?.color2 || gradients.blueLagoon[1]}
      );
    `};
    ${props =>
      props.gradient?.opacity &&
      css`
        opacity: ${props.gradient.opacity};
      `};

    ${({ side }) =>
      side === 'left'
        ? css`
            right: ${between(`${-127 / modularScale}px`, '-127px')};
          `
        : css`
            left: ${between(`${-127 / modularScale}px`, '-127px')};
          `}
  }
`

const Content = styled.div<{
  side: Side
}>`
  grid-column: 1 / span 12;

  @media screen and (min-width: 520px) {
    grid-column: 1 / span 9;
  }

  ${({ side }) =>
    side === 'left'
      ? css`
          ${media.md(css`
            grid-column: 1 / span 7;
            padding-right: ${({ theme }) => theme.spacing[2]};
          `)}
        `
      : css`
          ${media.md(css`
            grid-column: 6 / span 7;
            padding-left: ${({ theme }) => theme.spacing[2]};
          `)}
        `}
`

const ImageLandscape = styled.div`
  position: relative;
  padding-bottom: calc(227 / 344 * 100%);
`
const ImagePortrait = styled(ResponsiveParallax)`
  position: relative;

  padding-bottom: calc(236 / 174 * 100%); // 2:3 aspect-ratio

  ${media.md(css`
    padding-bottom: calc(3 / 2 * 100%); // 2:3 aspect-ratio
  `)}
`

const Portrait = styled.div<{
  side: Side
}>`
  grid-column: 8 / span 5;
  margin-right: ${`-${gridOffsetFrom}px`};
  margin-top: -40px;

  ${({ side }) =>
    media.md(css`
      grid-row: 1 / end;
      padding-top: ${({ theme }) => theme.spacing[4]};
      margin: 0;
      margin-left: 12px;

      ${side === 'left'
        ? css`
            grid-column: 8 / span 5;
          `
        : css`
            grid-column: 1 / span 5;
          `}
    `)}
`
const Landscape = styled.div<ImageProps>`
  grid-column: 1 / span 7;
  margin-left: ${`-${gridOffsetFrom}px`};
  margin-right: 12px;

  padding-top: 10px;
  ${({ side }) =>
    media.md(css`
      padding-top: 0;
      margin-left: 0;
      ${side === 'left'
        ? css`
            grid-column: 1 / span 7;
          `
        : css`
            grid-column: 6 / span 7;
          `}
    `)}
`

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2 }}
        maxWidth={600}
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Type weight="light" size={{ xs: 16, md: 24 }} bottom={{ xs: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <Button
              textColor={fields.textColor}
              paddingSize={fields.paddingSize}
              preset={fields.color}
              to={fields.link}
              style={{ width: 'max-content', minWidth: '160px' }}
              top={{ xs: 1 }}
              bottom={{ xs: 1 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
        default:
          return null
      }
    },
  },
}

export const DuoImagePanelSection = ({
  gradient,
  textContent,
  landscapeImage,
  portraitImage,
  textContentSide = 'left',
}: DuoImagePanelSectionProps) => {
  return (
    <Container gradient={gradient} side={textContentSide}>
      <Content side={textContentSide}>
        <RichTextRenderer document={textContent} customOptions={options} />
      </Content>

      {!!landscapeImage && (
        <Landscape side={textContentSide}>
          <ImageLandscape>
            <ContentfulImage
              image={landscapeImage}
              alt={landscapeImage?.fields?.description ?? ''}
              fill
            />
          </ImageLandscape>
        </Landscape>
      )}

      {!!portraitImage && (
        <Portrait side={textContentSide}>
          <ImagePortrait from={4} to={-4} disabledMobile>
            <ContentfulImage
              image={portraitImage}
              alt={portraitImage?.fields?.description ?? ''}
              fill
            />
          </ImagePortrait>
        </Portrait>
      )}
    </Container>
  )
}
