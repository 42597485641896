import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IGradientFields } from 'bl-common/src/generated/contentful'
import { Section } from 'bl-common/src/units/Section/Section'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { media } from 'bl-common/src/utils/media'

import { formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

const Container = styled.div<{ indent: unknown }>`
  position: relative;
  z-index: 1;
  padding: ${({ theme: { spacing }, indent }) =>
    `${spacing[4.5]} ${indent ? spacing[3.5] : '0'}`};

  ${media.md(css<{ indent: unknown }>`
    padding: ${({ theme: { spacing }, indent }) =>
      `${spacing[8.5]} ${indent ? spacing[6.5] : '0'}`};
  `)};
`

const Background = styled.div<{
  backgroundColor: string
  image: string
  backgroundGradient: IGradientFields
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `}

  ${({ image }) =>
    image &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url(${image});
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        z-index: 1;
        mix-blend-mode: overlay;
      }
    `}

  ${({ backgroundGradient }) =>
    backgroundGradient &&
    css`
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: '';
        z-index: 2;
        opacity: ${backgroundGradient.opacity || 1};
        background: linear-gradient(
          ${backgroundGradient.degrees || '0deg'},
          ${backgroundGradient.color1},
          ${backgroundGradient.color2}
        );
      }
    `}
`

const CopySection = props => {
  const {
    heading,
    multilineSubheading,
    backgroundGradient,
    backgroundImage,
    color,
    textAlignment,
    headingType,
    indent,
    config,
  } = getSectionProps(props)

  let headlinePreset
  switch (headingType) {
    case 'h4':
      headlinePreset = 'headlineSmall'
      break
    case 'h3':
      headlinePreset = 'headlineMedium'
      break
    case 'h2':
      headlinePreset = 'headline'
      break
    case 'h1':
    default:
      headlinePreset = 'headlineLarge'
  }

  const formattedGradient = formatGradient(backgroundGradient)

  return (
    <Section bottom={{ xs: 4, md: 6 }} top={{ xs: 2, md: 6 }} config={config}>
      <Container
        indent={indent}
        style={{ color: colors[color] || colors.dark }}
      >
        <SmartImage image={backgroundImage}>
          {imageProps => (
            <Background
              image={imageProps}
              backgroundGradient={formattedGradient}
              backgroundColor={colors.dark}
            />
          )}
        </SmartImage>
        {heading && (
          <Type
            as="h3"
            preset={headlinePreset} // make the headingType control this
            weight="bold"
            textAlign={textAlignment || 'left'}
            bottom={{
              xs: multilineSubheading ? 0.5 : 0,
              md: multilineSubheading ? 2 : 0,
            }}
          >
            {heading}
          </Type>
        )}
        {multilineSubheading && (
          <Type
            as="h4"
            preset="subtitle"
            maxWidth={1000}
            textAlign={textAlignment || 'left'}
            style={
              textAlignment && textAlignment === 'center'
                ? { margin: '0 auto' }
                : textAlignment && textAlignment === 'right'
                  ? { marginLeft: 'auto' }
                  : {}
            }
            multiline
          >
            {multilineSubheading}
          </Type>
        )}
      </Container>
    </Section>
  )
}

export default CopySection
