import { useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { mb, mt } from 'styled-components-spacing'

import { durations } from '../constants/durations'
import { gridOffsetFrom, gridOffsetTo } from '../constants/sizes'
import { zIndex } from '../constants/zIndex'
import { getImageUrl } from '../units/SmartImage/getImageUrl'
import { SmartImage } from '../units/SmartImage/SmartImage'
import { between } from '../utils/between'
import { media } from '../utils/media'
import { mixins } from '../utils/mixins'

type Breakpoint = 'xs' | 'md' | 'lg' | 'mlg'
type BreakpointMap<T> = Partial<Record<Breakpoint, T>>

type HeroImageProps = {
  children?: React.ReactNode
  shouldAnimate?: boolean
  shouldAnimateAcross?: boolean
  activeImage?: string
  size?: any
  image?: string
  offsetDirection?: string
  backgroundPosition?: string
  centerColumn?: boolean
  setHeight?: BreakpointMap<number | string>
  video?: any
  fillContainer?: boolean
}

type BackgroundProps = {
  setHeight?: HeroImageProps['setHeight']
  offsetDirection?: HeroImageProps['offsetDirection']
  backgroundPosition?: HeroImageProps['backgroundPosition']
  shouldAnimate?: HeroImageProps['shouldAnimate']
  shouldAnimateAcross?: HeroImageProps['shouldAnimateAcross']
  image?: HeroImageProps['image']
  top?: number | string
  bottom?: number | string
  isLoaded?: boolean
  backgroundContrast?: number
}

type ThumbnailProps = {
  image?: string
  isLoaded?: boolean
}

type ContentProps = {
  centerColumn?: boolean
  fillContainer?: boolean
  isVideo?: boolean
  offsetDirection?: string
}

const Background = styled.div<BackgroundProps>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;

  ${({ top, theme }) => mt(top, theme)};
  ${({ bottom, theme }) => mb(bottom, theme)};
  ${({ setHeight }) =>
    setHeight && map(setHeight, val => val && `min-height: ${val};`)};

  ${props =>
    props.offsetDirection === 'left' &&
    css`
      left: ${between(gridOffsetFrom, gridOffsetTo)};
    `};

  ${props =>
    props.offsetDirection === 'right' &&
    css`
      right: ${between(gridOffsetFrom, gridOffsetTo)};
    `};

  ${props =>
    props.backgroundPosition &&
    css`
      background-position: ${props.backgroundPosition};
    `}

  &::before {
    content: '';
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: -25px;
    right: -25px;
    left: -25px;
    bottom: -25px;
    z-index: ${zIndex.behind};

    .js {
      ${props =>
        !props.isLoaded &&
        css`
          background-image: none;
        `};
    }

    ${({ shouldAnimate, isLoaded }) =>
      shouldAnimate &&
      css`
        @keyframes scaleIn {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(1.05);
          }
        }

        animation-name: initial;
        animation-duration: 6s;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-fill-mode: both;

        ${isLoaded &&
        css`
          animation-name: scaleIn;
        `};
      `};

    ${({ shouldAnimateAcross, isLoaded }) =>
      shouldAnimateAcross &&
      css`
        right: -50px;
        bottom: -50px;
        max-width: 100%;

        animation-name: initial;
        opacity: 0;
        transition: opacity 0.3s ease;

        ${media.md(css`
          right: -100px;
          max-width: inherit;
        `)}

        ${isLoaded &&
        css`
          opacity: 0.5;
          animation:
            fade 2000ms ease forwards,
            move 6000ms ease forwards;

          ${media.md(css`
            animation:
              fade 2000ms ease forwards,
              move-large 6000ms ease forwards;
          `)}
        `};

        @keyframes fade {
          0% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes move {
          0% {
            transform: translate3d(-50px, 0, 0);
          }
          100% {
            transform: translate3d(0, -25px, 0);
          }
        }
        @keyframes move-large {
          0% {
            transform: translate3d(-100px, 0, 0);
          }
          100% {
            transform: translate3d(0, -25px, 0);
          }
        }
      `};
  }

  &::after {
    ${({ backgroundContrast }) => css`
      background: linear-gradient(
        rgba(0, 0, 0, ${backgroundContrast || 0}),
        transparent
      );
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: ${zIndex.behind};
    `};
  }
`

const Thumbnail = styled.div<ThumbnailProps>`
  background-image: ${props => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  bottom: -25px;
  filter: blur(25px);
  left: -25px;
  position: absolute;
  right: -25px;
  top: -25px;

  .no-js & {
    display: none;
  }

  ${props =>
    props.isLoaded &&
    css`
      opacity: 0;
      transition: opacity ${durations.long}ms;
    `};
`

const Content = styled.div<ContentProps>`
  display: flex;
  height: 100%;
  ${props =>
    props.centerColumn &&
    css`
      flex-direction: column;
      justify-content: center;
    `};

  ${props =>
    props.fillContainer &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `};

  ${({ offsetDirection, isVideo }) =>
    offsetDirection === 'left' &&
    !isVideo &&
    css`
      ${mixins.offset({ direction: 'left' })};
      ${mixins.offset({ direction: 'right', columns: 2 })};
    `};

  ${({ offsetDirection, isVideo }) =>
    offsetDirection === 'right' &&
    !isVideo &&
    css`
      ${mixins.horizontalPadding(2)};
    `};
`
const BaseHeroImage: React.FC<HeroImageProps> = ({
  children,
  shouldAnimate,
  shouldAnimateAcross,
  activeImage,
  size,
  image,
  offsetDirection,
  backgroundPosition,
  centerColumn,
  setHeight,
  video,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [thumb, setThumb] = useState(
    getImageUrl(image, {
      width: 100,
      exact: true,
      format: null,
      quality: null,
    })
  )

  const imageRef = useRef<HTMLImageElement | null>(null)

  useEffect(() => {
    setThumb(
      getImageUrl(image, {
        width: 100,
        exact: true,
        format: null,
        quality: null,
      })
    )
    setIsLoaded(false)
  }, [image])

  useEffect(() => {
    if (!activeImage) return

    // Ensure the activeImage has a protocol (https)
    const normalizedImageSrc = activeImage.startsWith('//')
      ? `https:${activeImage}`
      : activeImage

    const preloadImage = new Image()

    preloadImage.onload = () => {
      if (preloadImage.src === normalizedImageSrc) {
        setIsLoaded(true)
      }
    }

    preloadImage.src = normalizedImageSrc

    // Store the reference to the image for further debugging
    imageRef.current = preloadImage
  }, [activeImage])

  return (
    <Background
      setHeight={setHeight || { xs: '456px', md: '95vh' }}
      offsetDirection={offsetDirection}
      backgroundPosition={backgroundPosition}
      image={!video && activeImage}
      isLoaded={isLoaded}
      shouldAnimate={shouldAnimate}
      shouldAnimateAcross={shouldAnimateAcross}
      {...props}
    >
      <Content
        offsetDirection={offsetDirection}
        centerColumn={centerColumn}
        isVideo={!!video}
        {...props}
      >
        {video ? (
          <video
            src={get(video, 'fields.file.url')}
            autoPlay
            muted
            playsInline
            loop
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: backgroundPosition || 'center',
            }}
          />
        ) : (
          children
        )}
      </Content>
      {!video && !shouldAnimateAcross && (
        <Thumbnail image={thumb} isLoaded={isLoaded} />
      )}
    </Background>
  )
}

const HeroImage: React.FC<HeroImageProps> = props => (
  <SmartImage image={props.image}>
    {imageUrl => <BaseHeroImage {...props} activeImage={imageUrl} />}
  </SmartImage>
)

export default HeroImage
