import styled from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { Section } from 'bl-common/src/units/Section/Section'

import { MultiVideoItem } from './MultiVideoItem'

type ContainerProps = {
  numberOfItems: number
}

const Container = styled.div<ContainerProps>(({ theme, numberOfItems }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing[2.5],

  [`@media (min-width: ${breakpoints.md}px)`]: {
    gridTemplateColumns: `repeat(${numberOfItems}, 1fr)`,
  },
}))

const MultiVideoSection = ({
  section: {
    fields: {
      verticalSpacing,
      firstVideoId,
      firstTitle,
      firstDescription,
      secondVideoId,
      secondTitle,
      secondDescription,
      thirdVideoId,
      thirdTitle,
      thirdDescription,
      config,
    },
  },
}) => {
  const numberOfItems = [firstVideoId, secondVideoId, thirdVideoId]?.filter(
    id => !!id
  ).length

  const hasSecondItem = !!secondVideoId && !!secondTitle && !!secondDescription
  const hasThirdItem = !!thirdVideoId && !!thirdTitle && !!thirdDescription

  const textSize =
    numberOfItems === 1 ? 'lg' : numberOfItems === 2 ? 'md' : 'sm'

  const spacing =
    verticalSpacing === 'Large'
      ? { xs: 8, md: 12 }
      : verticalSpacing === 'Medium'
        ? { xs: 4, md: 6 }
        : { xs: 2, md: 3 }

  return (
    <Section config={config} top={spacing} bottom={spacing}>
      <Container numberOfItems={numberOfItems}>
        <MultiVideoItem
          videoId={firstVideoId}
          title={firstTitle}
          description={firstDescription}
          textOnTop={!hasSecondItem && !hasThirdItem}
          textSize={textSize}
        />

        {!!hasSecondItem && (
          <MultiVideoItem
            videoId={secondVideoId}
            title={secondTitle}
            description={secondDescription}
            textSize={textSize}
          />
        )}

        {!!hasThirdItem && (
          <MultiVideoItem
            videoId={thirdVideoId}
            title={thirdTitle}
            description={thirdDescription}
            textSize={textSize}
          />
        )}
      </Container>
    </Section>
  )
}

export default MultiVideoSection
